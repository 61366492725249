 html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin: 0;
  line-height: 1.5;
  color: #121212;
}
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");



#listScreens{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none ;  /* Firefox */
}

#listScreens::-webkit-scrollbar { 
  display: none ;  /* Safari and Chrome */
}



#quill-output .ql-align-center {
  text-align: center;
}

#quill-output .ql-align-right {
  text-align: right;
}

#quill-output .ql-align-justify {
  text-align: justify;
}


/* EDITOR  */
.DraftEditor-root img {
  display: block;
  margin: 10px auto;
}

.css-vsyu8f-MuiTableRow-root {
  background:  #F6F8FB !important;
}

.css-1cjiko4{
  background: #E8F1FF !important;
}


.css-1vlry5a-MuiTableContainer-root {
  width: 100%;
  overflow-x: auto;
  max-width: 100%;
  overflow: scroll;
  height: 50vh;
}


.css-1d45wrc-MuiTableContainer-root {
  width: 100%;
  overflow-x: auto;
  max-width: 100%;
  max-height: clamp(250px, calc(100vh - 45vh), 9999px) !important;
  overflow: auto;
}




.css-80pr5n-MuiPaper-root{
  box-shadow: none !important;
}


.react-calendar{
  border:none !important;
}


.react-calendar__tile--active{
  background-color: #DAE6FF !important;
  color:black !important;
}


.react-calendar__tile--hasActive{
  background-color: #4B81F4 !important;
}


/* DRAG AND DROP MEDIA  */


/* CSS for selected folder */
.selected-folder {
  background-color: lightgreen;
}

/* CSS for target folder */
.target-folder {
  background-color: lightgreen;
}



.node-content{
  display: flex;
}


.node-text{
display: flex;
gap:1rem;
cursor: grabbing;
padding-left:1rem;
}

.file-actions {
  opacity: 0;         /* Hidden by default */
  visibility: hidden; /* Not clickable by default */
  pointer-events: none; /* Disables mouse interactions */
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth transition for opacity and visibility */
}

/* Assuming .node-container is the parent element that you will hover over */
.node-container:hover .file-actions {
  opacity: 1;         /* Visible on hover */
  visibility: visible; /* Clickable on hover */
  pointer-events: auto; /* Enables mouse interactions on hover */
}


.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
  border:solid 1px black;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}
